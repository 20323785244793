import React from 'react';

const PrinterType = (props) => {
  const printer = props.printer;
  let printerType;
  switch (printer && printer.printer_type) {
    case 'gtx':
      printerType = 'GTX';
      break;
    case 'gtxpro':
      printerType = 'GTXpro';
      break;
    case 'gtxprobulk':
      printerType = 'GTXpro Bulk';
      break;
    case 'gtx600':
      printerType = 'GTX600';
      break;
    case 'gtx600sb':
      printerType = 'GTX600SB';
      break;
    case 'gt-3':
      printerType = 'GT-3';
      break;
    case 'gt341':
      printerType = 'GT-341';
      break;
    case 'gt361':
      printerType = 'GT-361';
      break;
    case 'gt381':
      printerType = 'GT-381';
      break;
    default:
      printerType = '';
  }

  return (
    <>
      {printerType}{printer && printer.machine && ' R2R'}
    </>
  );
};

export default PrinterType;
