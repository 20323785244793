import React, {useEffect, useState} from 'react';
import {
  Container,
  Row,
  Col,
  Input,
  Card,
  FormGroup,
  Label,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button, Modal, Alert,
} from 'reactstrap';

import {Link, useHistory, withRouter} from 'react-router-dom';
import {AvField, AvForm} from 'availity-reactstrap-validation';
import SweetAlert from 'react-bootstrap-sweetalert';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

// Import Images
import Dropzone from 'react-dropzone';
import ListPrinters from '../../components/Lists/ListPrinters';
import {connect} from 'react-redux';
import {apiError, createClaim} from '../../store/actions';
import AvSelect from '../../components/Common/AvSelect';
import Printhead from '../Printers/printhead';

import imageLogfile from '../../assets/images/icon-logfile.svg';
import imageNozzle from '../../assets/images/icon-nozzle.svg';
import imagePhoto from '../../assets/images/icon-photo.svg';
import imageFolder from '../../assets/images/icon-folder.svg';
import imagePrinter from '../../assets/images/icon-printer.svg';
import imageVehicle from '../../assets/images/icon-vehicle.svg';
import PrintheadStatus from '../Printers/printhead-status';
import moment from 'moment';

const compensationList = [
  {
    options: [
      {label: 'Exchange', value: 'Exchange'},
      {label: 'Credit', value: 'Credit'},
    ],
  },
];

const replacementTypes = [
  {
    options: [
      {label: 'Printhead Replacement', value: 1},
      {label: 'Other Part Replacement', value: 2},
    ],
  },
];

/* eslint-disable max-len */

const ClaimAdd = (props) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedPrinter, setSelectedPrinter] = useState(null);
  const [shippingInformation, setShippingInformation] = useState('');
  const [printHeadsReplacements, setPrintHeadsReplacements] = useState([]);
  const [otherPartsReplacements, setOtherPartsReplacements] = useState([]);
  const [replacementType, setReplacementType] = useState(null);
  const [modal, setModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [filesErrorMsg, setFilesErrorMsg] = useState(false);
  const [replacementErrorMsg, setReplacementErrorMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setIsLoading(false);
    if (props.data && props.data.success) {
      setSuccessMsg(true);
      setShippingInformation(props.data.file);
    } else if (props.data && props.data.error) {
      setErrorMsg(props.data.error);
    }
  }, [props.data]);

  function handleAcceptedFiles(files, target) {
    files.map((file) => Object.assign(file, {
      preview: URL.createObjectURL(file),
      formattedSize: formatBytes(file.size),
      target: target,
      ext: file.name.split('.').reverse()[0],
    }));

    const tempFiles = [...selectedFiles];

    files.map((file) => tempFiles.push(file));

    setSelectedFiles(tempFiles);
  }

  function removeFile(index) {
    setSelectedFiles(
        selectedFiles.slice(0, index).concat(selectedFiles.slice(
            index + 1, selectedFiles.length)),
    );
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  function handleSelectPrinter(selectedPrinter) {
    setSelectedPrinter(selectedPrinter);
    setPrintHeadsReplacements([]);
  }

  /* eslint-disable camelcase */
  function handleAddPrintHeadsReplacement(print_head) {
    setPrintHeadsReplacements([...printHeadsReplacements, print_head]);
  }

  function handleRemovePrintHeadsReplacement(e, idx) {
    setPrintHeadsReplacements(
        printHeadsReplacements.slice(0, idx).concat(
            printHeadsReplacements.slice(
                idx + 1, printHeadsReplacements.length),
        ),
    );
  }

  function handleAddOtherPartsReplacement(part) {
    setOtherPartsReplacements([...otherPartsReplacements, part]);
  }

  function handleRemoveOtherPartsReplacement(e, idx) {
    setOtherPartsReplacements(otherPartsReplacements.slice(
        0, idx).concat(otherPartsReplacements.slice(
        idx + 1, otherPartsReplacements.length)));
  }

  function handleValidSubmit(event, values) {
    if (!selectedFiles.length || !selectedFiles.filter(
        (file) => file.target === 'log').length) {
      setFilesErrorMsg(true);
    } else if (replacementType.value === 1 && !printHeadsReplacements.length) {
      setReplacementErrorMsg(true);
    } else if (replacementType.value === 1 && (!selectedFiles.filter(
        (file) => file.target === 'photo').length ||
      !selectedFiles.filter((file) => file.target === 'nozzle').length)) {
      setFilesErrorMsg(true);
    } else if (replacementType.value === 2 && !otherPartsReplacements.length) {
      setReplacementErrorMsg(true);
    } else if (replacementType.value === 2 && !selectedFiles.filter(
        (file) => file.target === 'photo').length) {
      setFilesErrorMsg(true);
    } else {
      setIsLoading(true);

      const claim = {
        printer_serial_no: selectedPrinter.printer_serial_no,
        customer_id: selectedPrinter.customer.id,
        number_of_prints: values.number_of_prints,
        date_of_visit: values.date_of_visit,
        information: values.information,
        err_code: values.err_code,
        compensation: values.compensation.value,
        replacement_type: replacementType.value,
        print_head_replacements: values.print_head_replacements,
        other_parts_replacements: values.other_parts_replacements,
        files: selectedFiles,
      };
      props.createClaim(claim);
    }
  }

  function toggle_modal() {
    setModal(!modal);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          {/* Render Breadcrumb */}
          <Breadcrumbs
            parent="/claims"
            title="Claim"
            breadcrumbItem="Add Claim"
          />
          {errorMsg ?
            <SweetAlert
              title="Error!"
              danger
              showConfirm={true}
              showCancel={false}
              confirmBtnBsStyle="danger"
              closeOnConfirm={true}
              onConfirm={() => {
                setErrorMsg(false);
              }}
            >
              {errorMsg}
            </SweetAlert> : ''
          }
          {successMsg ? <SweetAlert
            title="Good job!"
            success
            showConfirm={true}
            showCancel={false}
            confirmBtnBsStyle="yellow"
            onConfirm={() => {
              setSuccessMsg(false);
              history.push('/claims');
            }}
          >
            <div>
              <p>
                The claim has been added and is now being reviewed!
                We have send you an email confirmation.
              </p>
              <p>Please print the attached
                <a
                  className="link-highlight"
                  href={
                    'data:application/pdf;base64,' + shippingInformation.content
                  }
                  download={
                    shippingInformation.filename
                  }>
                  Shipping Informations
                </a>
                and send it along with your package.
              </p>
            </div>
            <Row className="align-items-center mt-2">
              <Col>
                <img alt={'icon printer'} className="mb-1" src={imagePrinter}/>
                <p>Please print the PDF</p>
              </Col>
              <Col>
                <img alt={'icon folder'} className="mb-1" src={imageFolder}/>
                <p>Add part and shipping form</p>
              </Col>
              <Col>
                <img alt={'icon vehicle'} className="mb-1" src={imageVehicle}/>
                <p>Send everything to us together within two weeks</p>
              </Col>
            </Row>
          </SweetAlert> : ''
          }
          {filesErrorMsg ?
            <SweetAlert
              title="Missing Files!"
              warning
              showConfirm={true}
              showCancel={false}
              confirmBtnBsStyle="yellow"
              onConfirm={() => {
                setFilesErrorMsg(false);
              }}
            >
              Please upload the required files.
            </SweetAlert> : ''
          }
          {replacementErrorMsg ?
            <SweetAlert
              title="Missing Replacements!"
              warning
              showConfirm={true}
              showCancel={false}
              confirmBtnBsStyle="yellow"
              onConfirm={() => {
                setReplacementErrorMsg(false);
              }}
            >
              Please add a replacement.
            </SweetAlert> : ''
          }
          <Row>
            <Col xs="12">
              <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}>
                <Card>
                  <CardBody>
                    <FormGroup className="mb-4" row>
                      <Label htmlFor="printer-serialno" md="2"
                        className="col-form-label">Printer SERIAL No.</Label>
                      <Col md="10">
                        <ListPrinters onChange={(printer) => {
                          handleSelectPrinter(printer);
                        }}/>
                      </Col>
                    </FormGroup>
                    {selectedPrinter && selectedPrinter.guarantee_state === 0 &&
                      <Alert className="mb-4" color="danger" role="alert">
                        You try to claim a printer that is not covered by
                        guarantee. Most possible the claim will be denied.
                        If a print head is under guarantee, please continue.
                      </Alert>
                    }
                  </CardBody>
                </Card>
                {selectedPrinter &&
                  <Card>
                    <CardBody>
                      <Row>
                        <Col sm="10">
                          <FormGroup>
                            <Label htmlFor="installation_date">
                              Installation Date of Machine
                            </Label>
                            <Input
                              id="installation_date"
                              name="installation_date"
                              type="text"
                              value={
                                selectedPrinter &&
                                moment(
                                    selectedPrinter.installation_date,
                                ).format('YYYY-MM-DD')
                              }
                              className="form-control"
                              disabled="disabled"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          <FormGroup>
                            <Label htmlFor="name">Customer</Label>
                            <Input id="name" name="name" type="text"
                              value={selectedPrinter &&
                                selectedPrinter.customer.name}
                              className="form-control"
                              disabled="disabled"/>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <Label htmlFor="post_code">Post code</Label>
                            <Input id="post_code" name="post_code"
                              type="text"
                              value={selectedPrinter &&
                                selectedPrinter.customer.zip_code}
                              className="form-control"
                              disabled="disabled"/>
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="city">City</Label>
                            <Input id="city" name="city" type="text"
                              value={selectedPrinter &&
                                selectedPrinter.customer.city}
                              className="form-control"
                              disabled="disabled"/>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <Label htmlFor="country">Country</Label>
                            <Input id="country" name="country"
                              value={selectedPrinter &&
                                selectedPrinter.customer.country}
                              type="text"
                              className="form-control"
                              disabled="disabled"/>
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="dealer">Dealer</Label>
                            <Input id="dealer" name="dealer"
                              value={selectedPrinter &&
                                selectedPrinter.dealer.name}
                              type="text"
                              className="form-control"
                              disabled="disabled"/>
                          </FormGroup>
                        </Col>
                        {selectedPrinter && selectedPrinter.engineer ?
                          <Col sm="6">
                            <FormGroup>
                              <Label htmlFor="engineer">Engineer</Label>
                              <Input id="engineer" name="engineer"
                                value={
                                  selectedPrinter &&
                                  selectedPrinter.engineer.firstname + ' ' +
                                  selectedPrinter.engineer.lastname}
                                type="text"
                                className="form-control"
                                disabled="disabled"/>
                            </FormGroup>
                          </Col> : ''
                        }
                      </Row>
                    </CardBody>
                  </Card>
                }
                {selectedPrinter &&
                  <Card>
                    <CardBody>
                      <CardTitle>General Information</CardTitle>
                      <CardSubtitle className="mb-3">Fill all information
                                            below</CardSubtitle>
                      <FormGroup className="mb-4" row>
                        <Label htmlFor="date_of_visit" md="2"
                          className="col-form-label">Date of visit *</Label>
                        <Col md="10">
                          <AvField
                            name="date_of_visit"
                            className="form-control"
                            placeholder="Enter date of visit *"
                            type="date"
                            max={moment().format('YYYY-MM-DD')}
                            validate={{required: {value: true}}}
                            errorMessage="Please provide a valid date."
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label htmlFor="machine-prints" md="2"
                          className="col-form-label">No. of machine
                                                prints*</Label>
                        <Col md={10}>
                          <AvField
                            name="number_of_prints"
                            value=""
                            className="form-control"
                            placeholder="No. of machine prints *"
                            type="number"
                            validate={{required: {value: true}}}
                            errorMessage="Please provide a valid date."
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="mb-4" row>
                        <Label htmlFor="problem-description" md="2"
                          className="col-form-label">
                          Detailed explanation of the problem *
                        </Label>
                        <Col md="10">
                          <AvField
                            name="information"
                            className="form-control"
                            type="textarea"
                            validate={{required: {value: true}}}
                            errorMessage="Please provide a detailed
                            explanation."
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label htmlFor="err_code" md="2"
                          className="col-form-label">Error codes</Label>
                        <Col md={10}>
                          <AvField
                            name="err_code"
                            className="form-control"
                            placeholder="Error codes"
                            type="text"
                            validate={
                              {
                                minLength: {value: 4},
                                maxLength: {value: 4},
                                pattern: {value: '^[0-9]+$'},
                              }
                            }
                            errorMessage="Please provide a valid code."
                            helpMessage="Example: 0001."
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="compensation"
                          md="2"
                          className="col-form-label">
                          Choose a compensation *
                        </Label>
                        <Col md={10}>
                          <AvSelect
                            name="compensation"
                            options={compensationList}
                            className="form-control"
                            helpMessage="Select a compensation *"
                            raw={true}
                            validate={{required: {value: true}}}
                            errorMessage="Please choose a valid option."
                          />
                        </Col>
                      </FormGroup>
                    </CardBody>
                  </Card>
                }
                {selectedPrinter &&
                  <Card>
                    <CardBody>
                      <FormGroup className="mb-4" row>
                        <Label htmlFor="compensation" md="2" className="col-form-label">Choose a
                                                replacement type *</Label>
                        <Col md={10}>
                          <AvSelect
                            name="replacement_type"
                            options={replacementTypes}
                            className="form-control"
                            helpMessage="Select an option *"
                            raw={true}
                            validate={{required: {value: true}}}
                            errorMessage="Please choose a valid option."
                            onChange={(e, v) => {
                              setReplacementType(v);
                            }}
                          />
                        </Col>
                      </FormGroup>
                    </CardBody>
                  </Card>
                }
                {replacementType && replacementType.value === 1 &&
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">Print Heads
                                            replacements</CardTitle>
                      <div data-repeater-list="outer-group" className="outer">
                        <div data-repeater-item="" className="outer">

                          <div className="inner-repeater mb-4">
                            <table style={{width: '100%'}}>
                              <tbody>

                                {printHeadsReplacements.map((print_head, idx) => (
                                  <tr id={'print_head_replacements' + idx}
                                    key={idx}>
                                    <td>
                                      <Row className="mb-2">
                                        <Col md="4">
                                          <Label
                                            htmlFor="original-ph-serialno"
                                            className="col-form-label">Original
                                                                                PH
                                                                                Serial</Label>
                                          <AvField
                                            name={'print_head_replacements[' + idx + '][\'original_serial_no\']'}
                                            className="form-control"
                                            placeholder="Original PH Serial"
                                            type="text"
                                            disabled={true}
                                            value={print_head.serial_no}
                                          />
                                          <AvField type="hidden" name={'print_head_replacements[' + idx + '][\'color\']'} value={print_head.color}/>
                                          <AvField type="hidden" name={'print_head_replacements[' + idx + '][\'ph_position\']'} value={print_head.ph_position}/>
                                        </Col>
                                        {selectedPrinter && (selectedPrinter.printer_type === 'gtx' || selectedPrinter.printer_type === 'gtxpro' || selectedPrinter.printer_type === 'gtxprobulk' || selectedPrinter.printer_type === 'gtx600' || selectedPrinter.printer_type === 'gtx600sb') ?
                                          <Col md="2">
                                            <Label
                                              htmlFor="original-ph-vrate"
                                              className="col-form-label">Original
                                                  V.Rating</Label>
                                            <AvField
                                              name={'print_head_replacements[' + idx + '][\'original_ph_rev\']'}
                                              className="form-control"
                                              placeholder="V.Rating"
                                              type="text"
                                              disabled={true}
                                              value={print_head.rev_no}
                                            />
                                          </Col> : ''
                                        }
                                        <Col md="2">
                                          <Label
                                            htmlFor="replace-ph-serialno"
                                            className="col-form-label">Replaced
                                                                                PH Serial</Label>
                                          {selectedPrinter && ['gt341', 'gt361', 'gt381', 'gt-3'].includes(selectedPrinter.printer_type) ?
                                            <Printhead
                                              name={'print_head_replacements[' + idx + '][\'replaced_serial_no\']'}
                                              placeholder="Replaced PH Serial"
                                              example="AGT1234"
                                            /> : ''
                                          }
                                          {selectedPrinter && selectedPrinter.printer_type === 'gtx' ?
                                            <Printhead
                                              name={'print_head_replacements[' + idx + '][\'replaced_serial_no\']'}
                                              placeholder="Replaced PH Serial"
                                              example="GXA1234"
                                            /> : ''
                                          }
                                          {selectedPrinter && (selectedPrinter.printer_type === 'gtxpro' || selectedPrinter.printer_type === 'gtxprobulk') && (print_head.color === 'color' || print_head.color === 'cmyk') ?
                                            <Printhead
                                              name={'print_head_replacements[' + idx + '][\'replaced_serial_no\']'}
                                              placeholder="Replaced PH Serial"
                                              example="GXA1234"
                                            /> : ''
                                          }
                                          {selectedPrinter && (selectedPrinter.printer_type === 'gtxpro' || selectedPrinter.printer_type === 'gtxprobulk') && print_head.color === 'white' ?
                                            <Printhead
                                              name={'print_head_replacements[' + idx + '][\'replaced_serial_no\']'}
                                              placeholder="Replaced PH Serial"
                                              example="XAA1234"
                                            /> : ''
                                          }
                                          {selectedPrinter && (selectedPrinter.printer_type === 'gtx600' || selectedPrinter.printer_type === 'gtx600sb')?
                                            <Printhead
                                              name={'print_head_replacements[' + idx + '][\'replaced_serial_no\']'}
                                              placeholder="Replaced PH Serial"
                                              example="6WA1234"
                                              pattern="^[0-9]{1}[A-Z]{2}[0-9]+$"
                                            /> : ''
                                          }
                                        </Col>
                                        {selectedPrinter && (selectedPrinter.printer_type === 'gtx' || selectedPrinter.printer_type === 'gtxpro' || selectedPrinter.printer_type === 'gtxprobulk' || selectedPrinter.printer_type === 'gtx600' || selectedPrinter.printer_type === 'gtx600sb') ?
                                            <Col md="2">
                                              <Label
                                                htmlFor="replace-ph-vrate"
                                                className="col-form-label">V.Rating</Label>
                                              <AvField
                                                name={'print_head_replacements[' + idx + '][\'replaced_ph_rev\']'}
                                                className="form-control"
                                                placeholder="V.Rating"
                                                type="number"
                                                validate={
                                                  {
                                                    required: {value: true},
                                                    max: {value: 99},
                                                    min: {value: 1},
                                                  }
                                                }
                                                errorMessage="Please provide a valid number."
                                              />
                                            </Col> : ''
                                        }
                                        <Col md="2" className="align-self-center">
                                          <Button
                                            onClick={(e) => {
                                              handleRemovePrintHeadsReplacement(e, idx);
                                            }
                                            }
                                            color="primary"
                                            className="btn-block inner"
                                            style={{width: '100%'}}
                                          >
                                            {' '}
                                            Delete{' '}
                                          </Button>
                                        </Col>
                                      </Row>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            {
                              printHeadsReplacements.filter((print_head) => print_head.head_guarantee_state === 0).length > 0 &&
                                <Alert className="mb-4" color="danger" role="alert">
                                    You try to claim a print head that is not covered by guarantee. Most
                                    possible the claim will be denied.
                                </Alert>
                            }
                            <button
                              type="button"
                              className="btn btn-yellow waves-effect waves-light "
                              onClick={() => {
                                toggle_modal();
                              }}
                              data-toggle="modal"
                              data-target=".bs-example-modal-center"
                            >
                              <i className="mdi mdi-plus-thick mr-1"></i> Add Replacement
                            </button>
                            <Modal
                              isOpen={modal}
                              size="lg"
                              toggle={() => {
                                toggle_modal();
                              }}
                              centered={true}
                            >
                              <div className="modal-body">
                                <button
                                  type="button"
                                  onClick={() => {
                                    setModal(false);
                                  }}
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                                <Row>
                                  <Col md={1}>
                                    <div className="avatar-sm">
                                      <span
                                        className="avatar-title rounded-circle bg-primary text-white">
                                        <i className="bx bx-refresh bx-sm rotateInUpLeft"></i>
                                      </span>
                                    </div>
                                  </Col>
                                  <Col md={11}>
                                    <div className="mb-2">
                                      <h5 className="modal-title mt-0">Print Head Replacement</h5>
                                      <p>Please select the print head you want to replace</p>
                                      <Row>
                                        <Col sm="6">
                                          <div className="d-flex mb-1">
                                            <div
                                              className="badge badge-dark text-yellow">
                                              PH Colour
                                            </div>
                                          </div>
                                          {
                                            selectedPrinter && selectedPrinter.print_heads.filter((print_head) => print_head.installed === 1 && print_head.color !== 'white').map((print_head, key) =>
                                              <div
                                                role="button"
                                                key={key}
                                                className={`d-flex mb-1 cursor-pointer disabled-${printHeadsReplacements.some((e) => e.id === print_head.id)}`}
                                                onClick={() => {
                                                  if (!printHeadsReplacements.some((e) => e.id === print_head.id)) {
                                                    handleAddPrintHeadsReplacement(print_head);
                                                    setModal(false);
                                                  }
                                                }}
                                              >
                                                <PrintheadStatus
                                                  print_head={print_head}
                                                  printer_type={
                                                    selectedPrinter.printer_type
                                                  }
                                                />
                                              </div>,
                                            )
                                          }
                                        </Col>
                                        <Col sm="6">
                                          <div className="d-flex mb-1">
                                            <div
                                              className="badge badge-dark
                                              text-yellow"
                                            >
                                              PH White
                                            </div>
                                          </div>
                                          {
                                            selectedPrinter &&
                                            selectedPrinter.print_heads.filter(
                                                (print_head) =>
                                                  print_head.installed === 1 &&
                                                print_head.color === 'white',
                                            ).map((print_head, key) =>
                                              <div
                                                role="button"
                                                key={key}
                                                className={
                                                  `d-flex mb-1 cursor-pointer 
                                                disabled-${printHeadsReplacements.some((e) => e.id === print_head.id)}`}
                                                onClick={() => {
                                                  if (!printHeadsReplacements.some((e) => e.id === print_head.id)) {
                                                    handleAddPrintHeadsReplacement(print_head);
                                                    setModal(false);
                                                  }
                                                }}
                                              >
                                                <PrintheadStatus
                                                  print_head={print_head}
                                                  printer_type={
                                                    selectedPrinter.printer_type
                                                  }
                                                />
                                              </div>,
                                            )
                                          }
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Modal>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                }
                {replacementType && replacementType.value === 2 &&
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">
                        Other parts replacements
                      </CardTitle>
                      <div data-repeater-list="outer-group" className="outer">
                        <div data-repeater-item="" className="outer">

                          <div className="inner-repeater mb-4">
                            <table style={{width: '100%'}}>
                              <tbody>

                                {otherPartsReplacements.map((item1, idx) => (
                                  <tr id={'other_parts_replacements' + idx}
                                    key={idx}>
                                    <td>
                                      <Row className="mb-2">
                                        <Col md="4">
                                          <Label
                                            htmlFor="part-number"
                                            className="col-form-label">
                                            Part Number *
                                          </Label>
                                          <AvField
                                            name={
                                              'other_parts_replacements' +
                                              '[' + idx + '][\'number\']'
                                            }
                                            value=""
                                            className="form-control"
                                            placeholder="Part Number *"
                                            type="text"
                                            validate={{required: {value: true}}}
                                            errorMessage="Please provide a
                                            valid part number."
                                          />
                                        </Col>
                                        <Col md="3">
                                          <Label
                                            htmlFor="part-name"
                                            className="col-form-label">
                                            Part Name *
                                          </Label>
                                          <AvField
                                            name={
                                              'other_parts_replacements' +
                                              '[' + idx + '][\'name\']'
                                            }
                                            value=""
                                            className="form-control"
                                            placeholder="Part Name *"
                                            type="text"
                                            validate={{required: {value: true}}}
                                            errorMessage="Please provide a
                                            valid part name."
                                          />
                                        </Col>
                                        <Col md="3">
                                          <Label
                                            htmlFor="part-quantity"
                                            className="col-form-label">
                                            Quantity *
                                          </Label>
                                          <AvField
                                            name={
                                              'other_parts_replacements' +
                                              '[' + idx + '][\'quantity\']'
                                            }
                                            value=""
                                            className="form-control"
                                            placeholder="Quantity *"
                                            type="text"
                                            validate={
                                              {
                                                required: {
                                                  value: true,
                                                  errorMessage: 'Please provide a valid quantity.',
                                                },
                                                pattern: {
                                                  value: '^[0-9]+$',
                                                  errorMessage: 'Whole numbers only',
                                                },
                                              }
                                            }
                                          />
                                        </Col>
                                        <Col
                                          md="2"
                                          className="align-self-center mt-2"
                                        >
                                          <Button
                                            onClick={
                                              (e) => {
                                                handleRemoveOtherPartsReplacement(e, idx);
                                              }
                                            }
                                            color="primary"
                                            className="btn-block inner"
                                            style={{width: '100%'}}
                                          >
                                            {' '}
                                            Delete{' '}
                                          </Button>
                                        </Col>
                                      </Row>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <Button
                              onClick={() => {
                                handleAddOtherPartsReplacement();
                              }}
                              color="yellow"
                              className="mt-1"
                            >
                              <i className="mdi mdi-plus-thick mr-1"/>
                              Add Replacement
                            </Button>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                }
                {replacementType &&
                  <Card className="shadow-none border mb-0">
                    <CardBody>
                      <CardTitle className="mb-3">Upload</CardTitle>
                      <CardSubtitle
                        className="mb-3"
                      >
                        In order to be able to check a claim, we need the log
                        file and photos, on which the problem can be clearly
                        identified, as well as a photo of the nozzle check
                      </CardSubtitle>
                      <Row className="align-items-center">
                        <Col>
                          <div className="d-flex mb-4">
                            <img alt={'icon log file'} src={imageLogfile} className="mr-2"/>
                            <div className="align-self-center">
                              <strong>
                                1. Latest Logfile (after replacement) *
                              </strong>
                              <p className="mb-0">of Printer (.log)</p>
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="d-flex mb-4">
                            <img alt={'icon photo'} src={imagePhoto} className="mr-2"/>
                            <div className="align-self-center">
                              <strong>2. Photo / Video of Problem *</strong>
                              <p className="mb-0">.jpg/.png/.pdf/.mp4</p>
                            </div>
                          </div>
                        </Col>
                        {replacementType.value === 1 &&
                          <Col>
                            <div className="d-flex mb-4">
                              <img alt={'icon nozzle'} src={imageNozzle} className="mr-2"/>
                              <div className="align-self-center">
                                <strong>3. Nozzle checks *</strong>
                                <p className="mb-0">.jpg/.png/.pdf</p>
                              </div>
                            </div>
                          </Col>
                        }
                      </Row>
                      <Row>
                        <Col>
                          <Dropzone
                            onDrop={(acceptedFiles) => {
                              handleAcceptedFiles(acceptedFiles, 'log');
                            }}
                            accept=".log"
                            multiple={false}
                          >
                            {({getRootProps, getInputProps}) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message needsclick">
                                    <div className="mb-3">
                                      <i
                                        className="display-4 text-muted bx
                                        bxs-cloud-upload"/>
                                    </div>
                                    <h4>Drop files here or click to upload.</h4>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </Col>
                        <Col>
                          <Dropzone
                            onDrop={(acceptedFiles) => {
                              handleAcceptedFiles(acceptedFiles, 'photo');
                            }}
                            accept=".jpg,.png,.pdf,.mp4"
                            multiple={true}
                          >
                            {({getRootProps, getInputProps}) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message needsclick">
                                    <div className="mb-3">
                                      <i
                                        className="display-4 text-muted bx
                                        bxs-cloud-upload"
                                      />
                                    </div>
                                    <h4>Drop files here or click to upload.</h4>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </Col>
                        {replacementType.value === 1 &&
                          <Col>
                            <Dropzone
                              onDrop={(acceptedFiles) => {
                                handleAcceptedFiles(acceptedFiles, 'nozzle');
                              }}
                              accept=".jpg,.png,.pdf"
                              multiple={true}
                            >
                              {({getRootProps, getInputProps}) => (
                                <div className="dropzone">
                                  <div
                                    className="dz-message needsclick"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="dz-message needsclick">
                                      <div className="mb-3">
                                        <i className="display-4 text-muted bx
                                        bxs-cloud-upload"/>
                                      </div>
                                      <h4>
                                        Drop files here or click to upload.
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                          </Col>
                        }
                      </Row>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {selectedFiles.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border
                              dz-processing dz-image-preview dz-success
                              dz-complete"
                              key={i + '-file'}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    {(f.ext !== 'pdf' && f.ext !== 'log') &&
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />}
                                    {f.ext === 'pdf' &&
                                      <i
                                        className="bx bxs-file-pdf font-size-24"
                                      />}
                                    {f.ext === 'log' &&
                                      <i
                                        className="bx bxs-file-txt font-size-24"
                                      />}
                                  </Col>
                                  <Col>
                                    <div className="font-weight-bold">
                                      {f.name}
                                    </div>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                  <Col>
                                    <div
                                      className="text-danger font-weight-bold
                                      text-right cursor-pointer"
                                      onClick={() => removeFile(i)}>
                                      <i
                                        className="bx bx-x-circle font-size-24"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          );
                        })}
                      </div>
                    </CardBody>
                  </Card>
                }
                <Row className="my-4">
                  <Col sm="6">
                    <Link to="/claims"
                      className="btn d-none d-sm-inline-block btn-link">
                      <i className="mdi mdi-arrow-left mr-1"/>
                      Back to Claims Overview
                    </Link>
                  </Col>
                  <Col sm="6">
                    <div className="text-sm-right">
                      {isLoading ?
                        <Button
                          className="btn btn-yellow"
                          type="submit"
                          disabled={true}
                        >
                          <i
                            className="bx bx-loader bx-spin font-size-16
                            align-middle mr-2"/>
                          Add Claim
                        </Button> :
                        <Button className="btn btn-yellow" type="submit">
                          <i className="mdi mdi-plus-thick mr-1"/>
                          Add Claim
                        </Button>
                      }
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
/* eslint-enable max-len */
/* eslint-enable camelcase */

const mapStatetoProps = (state) => {
  const {data} = state.Claim;
  state.Claim = {};
  return {data};
};

export default withRouter(
    connect(mapStatetoProps, {createClaim, apiError})(ClaimAdd),
);
