import React from 'react';
import {Alert, Col, FormGroup, Label, Row} from 'reactstrap';
import {AvField} from 'availity-reactstrap-validation';
import Printhead from './printhead';
import Vrate from './vrate';

const Gtx = (props) => {
  return (
    <React.Fragment>
      <FormGroup className="mb-4">
        <Label className="col-form-label">Print Head Serials</Label>
        <Alert color="danger" role="alert">
            Please note: the following print head serial numbers are the
            original print heads that are installed with the installation
        </Alert>
        <table style={{width: '100%'}}>
          <tbody>
            <tr>
              <td>
                <Row className="mb-2 px-2">
                  <Label md="2"
                    className="col-form-label">PH
                                    Serial colors *</Label>
                  <Col md="4">
                    <Printhead
                      example="GXA1234"
                      name="print_heads[gtx][0][serial]"
                      placeholder="PH Serial colors *"
                    />
                    <AvField
                      name="print_heads[gtx][0][position]"
                      value="1"
                      type="text"
                      hidden={true}
                    />
                    <AvField
                      name="print_heads[gtx][0][color]"
                      value="color"
                      type="text"
                      hidden={true}
                    />
                  </Col>

                  <Label md="2"
                    className="col-form-label">V.Rating
                                    *</Label>
                  <Col md="4">
                    <Vrate name="print_heads[gtx][0][vrate]"/>
                  </Col>
                </Row>
              </td>
            </tr>
            <tr>
              <td>
                <Row className="mb-2 px-2">
                  <Label md="2"
                    className="col-form-label">PH
                                    Serial white *</Label>
                  <Col md="4">
                    <Printhead
                      example="GXA1234"
                      name="print_heads[gtx][1][serial]"
                      placeholder="PH Serial white *"
                    />
                    <AvField
                      name="print_heads[gtx][1][position]"
                      value="2"
                      type="text"
                      hidden={true}
                    />
                    <AvField
                      name="print_heads[gtx][1][color]"
                      value="white"
                      type="text"
                      hidden={true}
                    />
                  </Col>
                  <Label md="2"
                    className="col-form-label">V.Rating
                                    *</Label>
                  <Col md="4">
                    <Vrate name="print_heads[gtx][1][vrate]"/>
                  </Col>
                </Row>
              </td>
            </tr>
          </tbody>
        </table>
      </FormGroup>
    </React.Fragment>
  );
};

export default Gtx;
