import React from 'react';

import {connect} from 'react-redux';

import {Link} from 'react-router-dom';

// Redux Store
import {showRightSidebarAction, toggleLeftmenu} from '../../store/actions';
// reactstrap

// Import menuDropdown
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';

import logo from '../../assets/images/logo.svg';

// i18n
import {withTranslation} from 'react-i18next';
import NotificationDropdown from
  '../CommonForBoth/TopbarDropdown/NotificationDropdown';
import SwitchDealerDropdown from
  '../CommonForBoth/TopbarDropdown/SwitchDealerDropdown';
import {Badge} from 'reactstrap';

const Header = (props) => {
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo">
                <span className="logo-sm">
                  <img src={logo} alt="" height="20"/>
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="30"/>
                </span>
              </Link>
            </div>

            <div className="align-self-center ml-2">
              <Badge
                color="white"
                className="font-size-18 text-uppercase font-weight-bold">
                {process.env.REACT_APP_REALM}
              </Badge>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item
              waves-effect waves-light"
              data-toggle="collapse"
              onClick={() => {
                props.toggleLeftmenu(!props.leftMenu);
              }}
              data-target="#topnav-menu-content">
              <i className="fa fa-fw fa-bars"></i>
            </button>
          </div>

          <div className="d-flex">

            {['bimh'].includes(process.env.REACT_APP_REALM) ?
              <Link to={'/downloads'} className="d-none d-lg-block">
                <button
                  type="button"
                  className="btn btn-sm header-item waves-effect waves-light
                  d-flex align-items-center">
                  <i className="mdi mdi-cloud-download font-size-16"></i>
                  <span className="font-size-13 ml-2">
                      Software & other tools
                  </span>
                </button>
              </Link> : ''
            }

            {['bbo'].includes(process.env.REACT_APP_REALM) ?
                <a href="https://base.brother.digital/" className="d-none d-lg-block">
                  <button
                    type="button"
                    className="btn btn-sm header-item waves-effect waves-light
                  d-flex align-items-center">
                    <i className="mdi mdi-cloud-download font-size-16"></i>
                    <span className="font-size-13 ml-2">
                      Software & other tools
                    </span>
                  </button>
                </a> : ''
            }

            <Link to={'/helpcenter'} className="d-none d-lg-block">
              <button
                type="button"
                className="btn btn-sm font-size-13 header-item waves-effect
                waves-light d-flex align-items-center">
                <i className="fa fa-fw fa-question-circle font-size-16"></i>
                <span className="font-size-13 ml-2">Helpcenter</span>
              </button>
            </Link>

            <SwitchDealerDropdown/>

            <NotificationDropdown/>

            <ProfileMenu/>

            {/* <div className="dropdown d-inline-block">*/}
            {/*  <button*/}
            {/*    onClick={() => {*/}
            {/*      props.showRightSidebarAction(!props.showRightSidebar);*/}
            {/*    }}*/}
            {/*    type="button"*/}
            {/*    className="btn header-item noti-icon right-bar-toggle*/}
            {/*    waves-effect" >*/}
            {/*    <i className="bx bx-cog bx-spin"></i>*/}
            {/*  </button>*/}
            {/* </div>*/}
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const {layoutType, showRightSidebar, leftMenu} = state.Layout;
  return {layoutType, showRightSidebar, leftMenu};
};

export default connect(
    mapStatetoProps, {showRightSidebarAction, toggleLeftmenu},
)(withTranslation()(Header));


