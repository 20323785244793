import React, {useState, useEffect, useCallback} from 'react';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
} from 'reactstrap';
import {connect} from 'react-redux';
import {createCustomer} from '../../store/customer/actions';
import {withRouter, Link, useHistory} from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

// availity-reactstrap-validation
import {
  AvForm,
  AvCheckbox,
  AvCheckboxGroup,
  AvField,
  AvRadioGroup,
  AvRadio,
} from 'availity-reactstrap-validation';
import '@availity/reactstrap-validation-select/styles.scss';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import ListCountries from '../../components/Lists/ListCountries';
import {apiError} from '../../store/auth/login/actions';
import authHeader from '../../helpers/jwt-token-access/auth-token-header';
import axios from 'axios';
import ModalExistingCustomers from
  '../../components/Modals/modal-existingcustomers';
import _debounce from 'lodash.debounce';

const CustomerAdd = (props) => {
  const [errorMsg, setErrorMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  // const [environmentalConditionsTemperature,
  // setEnvironmentalConditionsTemperature] = useState(0);
  // const [environmentalConditionsHumidity,
  // setEnvironmentalConditionsHumidity] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [customerStatus, setCustomerStatus] = useState(false);
  const [existingCustomers, setExistingCustomers] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  // const formatHumidity = value => value + "%";
  // const formatTemperature = value => value + " °C";

  useEffect(() => {
    setIsLoading(false);
    if (props.data && props.data.success) {
      setSuccessMsg(true);
    } else if (props.data && props.data.error) {
      setErrorMsg(props.data.error);
    }
  }, [props.data]);

  function handleSelectCountry(selectedCountry) {
    setSelectedCountry(selectedCountry);
  }

  // eslint-disable-next-line no-unused-vars
  function handleSelectCustomer(customer) {
    setSelectedCustomer(customer);
  }

  function handleChangeName(name) {
    debounce(name);
  }

  const debounce = useCallback(
      _debounce((name) => {
        const url = process.env.REACT_APP_APIURL + 'customer/checkduplicate';
        const authToken = authHeader();

        const formData = new FormData();
        formData.append('name', name);

        return axios.post(
            url, formData, {headers: authToken},
        ).then((response) => {
          setCustomerStatus(response.status === 201);
          setExistingCustomers(null);
        }).catch((error) => {
          setCustomerStatus(true);
          setExistingCustomers(error.response.data.customers);
        });
      }, 2000),
      [],
  );


  function handleValidSubmit(event, values) {
    setIsLoading(true);

    const customer = {
      name: values.name,
      contact_name: values.contact_name,
      additional_address: values.additional_address,
      street: values.street,
      street_no: values.street_no,
      zip_code: values.zip_code,
      city: values.city,
      country: selectedCountry.name,
      phone: values.phone,
      email: values.email,
      www: values.www,
      // logo: values.logo,
      dealer_id: values.dealer_id,
      business_information: {
        type_of_business: [
          ...values.type_of_business,
          values.type_of_business_other,
        ],
        no_of_staff: values.no_of_staff,
        printed_media: [...values.printed_media, values.printed_media_other],
        projected_print_amount: values.projected_print_amount,
        environmental_conditions: values.environmental_conditions,
        environmental_conditions_temperature: values.room_temperatur,
        environmental_conditions_humidity: values.room_humidity,
        operating_system: [
          ...values.operating_system,
          values.operating_system_other,
        ],
        pre_treatment: [...values.pre_treatment, values.pre_treatment_other],
        pre_treatment_info: values.pre_treatment_info,
        heatpress_brand: values.heatpress_brand,
        dryer_brand: values.dryer_brand,
        graphics_software: [
          ...values.graphics_software,
          values.graphics_software_other,
        ],
        white_shaking_tools: values.white_shaking_tools,
      },
    };
    props.createCustomer(customer);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          {/* Render Breadcrumb */}
          <Breadcrumbs
            parent="/customers"
            title="Customers"
            breadcrumbItem="Add Customer"
          />

          <Row>
            <Col xs="12">
              <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}>
                {errorMsg ?
                  <SweetAlert
                    title="Error!"
                    danger
                    showConfirm={true}
                    showCancel={false}
                    confirmBtnBsStyle="danger"
                    closeOnConfirm={true}
                    onConfirm={() => {
                      setErrorMsg(false);
                    }}
                  >
                    {errorMsg}
                  </SweetAlert> : ''
                }
                {successMsg ? <SweetAlert
                  title="Good job!"
                  success
                  showConfirm={true}
                  showCancel={false}
                  confirmBtnBsStyle="yellow"
                  onConfirm={() => {
                    history.push('/customers');
                  }}
                >
                  The customer has been added!
                </SweetAlert> : null}
                <ModalExistingCustomers customers={existingCustomers}/>
                {/* {customerStatus && <React.Fragment>*/}
                <Card>
                  <CardBody>
                    <CardTitle>Customer Informations</CardTitle>
                    <CardSubtitle className="mb-3">
                      Fill all information below
                    </CardSubtitle>
                    <Row>
                      <Col md="6">
                        <AvField
                          name="name"
                          value=""
                          className="form-control"
                          placeholder="Company name *"
                          label="Company"
                          type="text"
                          validate={
                            {
                              required: {value: true},
                              // async: (value, ctx, input, cb) => {
                              //     cb(customerStatus);
                              // }
                            }
                          }
                          // onInput={debounce((e) => , 100)}
                          onChange={(e) => handleChangeName(e.target.value)}
                          errorMessage="Please provide a valid name."
                        />
                      </Col>
                      <Col md="4">
                        <AvField
                          name="contact_name"
                          value=""
                          className="form-control"
                          placeholder="Contact name *"
                          label="Contact"
                          type="text"
                          validate={{required: {value: true}}}
                          errorMessage="Please provide a contact name."
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <AvField
                          name="additional_address"
                          value=""
                          className="form-control"
                          placeholder="Additional Address (optional)"
                          label="Additional Address"
                          type="text"
                          validate={{required: {value: false}}}
                          errorMessage="Please provide a valid additional
                          address."
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <AvField
                          name="street"
                          value=""
                          className="form-control"
                          placeholder="Street *"
                          label="Street"
                          type="text"
                          validate={{required: {value: true}}}
                          errorMessage="Please provide a valid street."
                        />
                      </Col>
                      <Col md="2">
                        <AvField
                          name="street_no"
                          value=""
                          className="form-control"
                          placeholder="Street No *"
                          label="Street No"
                          type="text"
                          validate={{required: {value: true}}}
                          errorMessage="Please provide a valid street no."
                        />
                      </Col>
                      <Col md="2">
                        <AvField
                          name="zip_code"
                          value=""
                          className="form-control"
                          placeholder="Zip Code *"
                          label="Zip Code"
                          type="text"
                          validate={{required: {value: true}}}
                          errorMessage="Please provide a valid zip code."
                        />
                      </Col>
                      <Col md="3">
                        <AvField
                          name="city"
                          value=""
                          className="form-control"
                          placeholder="City *"
                          label="City"
                          type="text"
                          validate={{required: {value: true}}}
                          errorMessage="Please provide a valid city."
                        />
                      </Col>
                      <Col md="3">
                        <Label htmlFor="country"
                          className="col-form-label">Country</Label>
                        <ListCountries onChange={(country) => {
                          handleSelectCountry(country);
                        }} required={true}/>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <AvField
                          name="phone"
                          value=""
                          className="form-control"
                          placeholder="Phone *"
                          label="Phone"
                          type="text"
                          validate={{required: {value: true}}}
                          errorMessage="Please provide a valid phone number."
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <AvField
                          name="email"
                          value=""
                          className="form-control"
                          placeholder="Email Address*"
                          label="Email"
                          type="text"
                          validate={{required: {value: true}}}
                          errorMessage="Please provide a valid email address."
                        />
                      </Col>
                      <Col md="4">
                        <AvField
                          name="www"
                          value=""
                          className="form-control"
                          placeholder="Web Address *"
                          label="Web Address"
                          type="text"
                          validate={{required: {value: true}}}
                          errorMessage="Please provide a web address."
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle>Business Information</CardTitle>
                    <CardSubtitle className="mb-3">
                      Fill all information below
                    </CardSubtitle>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="type_of_business"
                        md="2"
                        className="col-form-label">
                        Business Type
                      </Label>
                      <Col md="5">
                        <AvCheckboxGroup name="type_of_business">
                          <Row>
                            <Col md="6">
                              <AvCheckbox customInput label="Embroidery"
                                value="Embroidery"/>
                              <AvCheckbox customInput label="Entertainment"
                                value="Entertainment"/>
                              <AvCheckbox customInput label="DTF"
                                value="DTF"/>
                              <AvCheckbox customInput label="Fashion"
                                value="Fashion"/>
                              <AvCheckbox customInput label="Heat Transfer"
                                value="Heat Transfer"/>
                            </Col>
                            <Col md="6">
                              <AvCheckbox customInput label="Internet"
                                value="Internet"/>
                              <AvCheckbox customInput label="Promotion"
                                value="Promotion"/>
                              <AvCheckbox customInput label="Screen Printing"
                                value="Screen Printing"/>
                              <AvCheckbox customInput label="Signage"
                                value="Signage"/>
                              <AvCheckbox customInput label="Retailshop"
                                value="Retailshop"/>
                            </Col>
                          </Row>
                        </AvCheckboxGroup>
                        <AvField
                          className="w-50"
                          type="text"
                          name="type_of_business_other"
                          label="Other"/>
                      </Col>

                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="no_of_staff"
                        md="2"
                        className="col-form-label"
                      >
                        No. of Staff
                      </Label>
                      <Col md="10">
                        <AvRadioGroup name="no_of_staff" required>
                          <AvRadio customInput label="1" value="1"/>
                          <AvRadio customInput label="3-5" value="3-5"/>
                          <AvRadio customInput label="6-10" value="6-10"/>
                          <AvRadio customInput label="10+" value="10+"/>
                        </AvRadioGroup>
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="printed_media"
                        md="2"
                        className="col-form-label"
                      >
                        Media to be printed with
                      </Label>
                      <Col md="10">
                        <AvCheckboxGroup name="printed_media">
                          <AvCheckbox customInput label="Caps" value="Caps"/>
                          <AvCheckbox customInput label="Shoes" value="Shoes"/>
                          <AvCheckbox
                            customInput
                            label="T-Shirts"
                            value="T-Shirts"
                          />
                          <AvCheckbox customInput label="Sweatshirts"
                            value="Sweatshirts"/>
                          <AvCheckbox
                            customInput
                            label="Hoodies"
                            value="Hoodies"
                          />
                        </AvCheckboxGroup>
                        <AvField
                          className="w-25"
                          type="text"
                          name="printed_media_other"
                          label="Other"/>
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label htmlFor="projected_print_amount" md="2"
                        className="col-form-label">
                        Print amount per month
                      </Label>
                      <Col md="10">
                        <AvRadioGroup name="projected_print_amount" required>
                          <AvRadio customInput label="<50" value="<50"/>
                          <AvRadio customInput label="50-100" value="50-100"/>
                          <AvRadio customInput label="101-500" value="101-500"/>
                          <AvRadio
                            customInput
                            label="501-1999"
                            value="501-1999"
                          />
                          <AvRadio customInput label=">2000" value=">2000"/>
                        </AvRadioGroup>
                      </Col>
                    </FormGroup>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle>Environmental Conditions</CardTitle>
                    <FormGroup className="mb-4">
                      <AvCheckboxGroup name="environmental_conditions">
                        <AvCheckbox customInput label="Air Conditionning"
                          value="Air Conditionning"/>
                        <AvCheckbox
                          customInput
                          label="Humidifier"
                          value="Humidifier"
                        />
                      </AvCheckboxGroup>
                    </FormGroup>
                    <Row>
                      <Col md={2}>
                        <AvField type="text" name="room_temperatur"
                          label="Temperature (in °C)"/>
                      </Col>
                      <Col md={2}>
                        <AvField type="text" name="room_humidity"
                          label="Room Humidity (in %)"/>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle>Operating System</CardTitle>
                    <FormGroup className="mb-4" row>
                      <Col md="10">
                        <AvCheckboxGroup name="operating_system">
                          <Label
                            htmlFor="printed_media"
                            md="2"
                            className="col-form-label"
                          >
                            Windows
                          </Label>
                          <AvCheckbox
                            customInput
                            label="Windows 7"
                            value="Windows 7"
                          />
                          <AvCheckbox
                            customInput
                            label="Windows 8"
                            value="Windows 8"
                          />
                          <AvCheckbox
                            customInput
                            label="Windows 10"
                            value="Windows 10"
                          />
                          <AvCheckbox
                            customInput
                            label="Other"
                            value="Other"
                          />
                          <Label
                            htmlFor="printed_media"
                            md="2"
                            className="col-form-label"
                          >
                            Macintosh
                          </Label>
                          <AvCheckbox customInput label="10.10 Yosemite"
                            value="10.10 Yosemite"/>
                          <AvCheckbox customInput label="10.11 El Capitan"
                            value="10.11 El Capitan"/>
                          <AvCheckbox customInput label="10.12 Sierra"
                            value="10.12 Sierra"/>
                          <AvCheckbox customInput label="10.13 High Sierra"
                            value="10.13 High Sierra"/>
                          <AvCheckbox customInput label="10.14 Mojave"
                            value="10.14 Mojave"/>
                          <AvCheckbox customInput label="10.15 Catalina"
                            value="10.15 Catalina"/>
                        </AvCheckboxGroup>
                      </Col>
                    </FormGroup>
                    <AvField
                      className="w-25"
                      type="text"
                      name="operating_system_other"
                      label="Other"
                    />
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle>Pre-Treatment Application</CardTitle>
                    <AvCheckboxGroup name="pre_treatment">
                      <AvCheckbox customInput label="PT-Line" value="PT-Line"/>
                      <AvCheckbox customInput label="PTM4" value="PTM4"/>
                      <AvCheckbox customInput label="PTM3" value="PTM3"/>
                      <AvCheckbox customInput label="Manual" value="Manual"/>
                    </AvCheckboxGroup>
                    <AvField
                      className="w-25"
                      type="text"
                      name="pre_treatment_other"
                      label="Other"
                    />
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle>Heat Press Info</CardTitle>
                    <AvField type="text" name="heatpress_brand" label="Brand"/>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle>Conveyor Dryer Info</CardTitle>
                    <AvField type="text" name="dryer_brand" label="Brand"/>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle>Software related</CardTitle>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="graphics_software"
                        md="2"
                        className="col-form-label"
                      >
                        Which Graphics Software is used by customer
                      </Label>
                      <Col md="10">
                        <AvCheckboxGroup name="graphics_software">
                          <AvCheckbox customInput label="Cadlink"
                            value="Cadlink"/>
                          <AvCheckbox
                            customInput label="Corel Draw"
                            value="Corel Draw"
                          />
                          <AvCheckbox customInput label="GraphicsLab"
                            value="GraphicsLab"/>
                          <AvCheckbox customInput label="Illustrator"
                            value="Illustrator"/>
                          <AvCheckbox
                            customInput
                            label="Photoshop"
                            value="Photoshop"
                          />
                        </AvCheckboxGroup>
                        <AvField
                          className="w-25"
                          type="text"
                          name="graphics_software_other"
                          label="Other"/>
                      </Col>
                    </FormGroup>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle>GTXpro Bulk Customer only</CardTitle>
                    <FormGroup className="mb-4" row>
                      <Col md="10">
                        <AvCheckboxGroup name="white_shaking_tools">
                          <AvCheckbox customInput label="White Shaking Tools"
                            value="true"/>
                        </AvCheckboxGroup>
                      </Col>
                    </FormGroup>
                  </CardBody>
                </Card>

                <Row className="my-4">
                  <Col sm="6">
                    <Link to="/customers"
                      className="btn d-none d-sm-inline-block btn-link">
                      <i className="mdi mdi-arrow-left mr-1"/>
                      Back to Customers Overview
                    </Link>
                  </Col>
                  <Col sm="6">
                    <div className="text-sm-right">
                      {isLoading ?
                        <Button
                          className="btn btn-yellow"
                          type="submit"
                          disabled={true}
                        >
                          <i
                            className="bx bx-loader bx-spin font-size-16
                            align-middle mr-2"/>
                          Submit Customer
                        </Button> :
                        <Button className="btn btn-yellow" type="submit">
                          <i className="mdi mdi-plus-thick mr-1"/>
                          Submit Customer
                        </Button>
                      }
                    </div>
                  </Col>
                </Row>
                {/* </React.Fragment>}*/}
              </AvForm>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


const mapStatetoProps = (state) => {
  const {data} = state.Customer;
  state.Customer = {};
  return {data};
};

export default withRouter(connect(
    mapStatetoProps, {createCustomer, apiError},
)(CustomerAdd));
