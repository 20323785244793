import React from 'react';
import {Alert, Col, FormGroup, Label, Row} from 'reactstrap';
import {AvField} from 'availity-reactstrap-validation';
import Printhead from './printhead';

const Gt341 = (props) => {
  return (
    <React.Fragment>
      <FormGroup className="mb-4">
        <Label className="col-form-label">Print Head Serials</Label>
        <Alert color="danger" role="alert">
                    Please note: the following print head serial numbers are
            the original print heads that are installed with the installation
        </Alert>
        <table style={{width: '100%'}}>
          <tbody>
            <tr>
              <td>
                <Row className="mb-2 px-2">
                  <Label md="2"
                    className="col-form-label">PH C
                                    Serial*</Label>
                  <Col md="4">
                    <Printhead
                      example="AGT1234"
                      name="print_heads[gt341][0][serial]"
                      placeholder="PH C Serial *"
                    />
                    <AvField
                      name="print_heads[gt341][0][position]"
                      value="c"
                      type="text"
                      hidden={true}
                    />
                    <AvField
                      name="print_heads[gt341][0][color]"
                      value="color"
                      type="text"
                      hidden={true}
                    />
                    <AvField
                      name="print_heads[gt341][0][vrate]"
                      value=""
                      type="text"
                      hidden={true}
                    />
                  </Col>
                </Row>
              </td>
            </tr>
            <tr>
              <td>
                <Row className="mb-2 px-2">
                  <Label md="2"
                    className="col-form-label">PH M
                                    Serial*</Label>
                  <Col md="4">
                    <Printhead
                      example="AGT1234"
                      name="print_heads[gt341][1][serial]"
                      placeholder="PH M Serial *"
                    />
                    <AvField
                      name="print_heads[gt341][1][position]"
                      value="m"
                      type="text"
                      hidden={true}
                    />
                    <AvField
                      name="print_heads[gt341][1][color]"
                      value="color"
                      type="text"
                      hidden={true}
                    />
                    <AvField
                      name="print_heads[gt341][1][vrate]"
                      value=""
                      type="text"
                      hidden={true}
                    />
                  </Col>
                </Row>
              </td>
            </tr>
            <tr>
              <td>
                <Row className="mb-2 px-2">
                  <Label md="2"
                    className="col-form-label">PH Y
                                    Serial*</Label>
                  <Col md="4">
                    <Printhead
                      example="AGT1234"
                      name="print_heads[gt341][2][serial]"
                      placeholder="PH Y Serial *"
                    />
                    <AvField
                      name="print_heads[gt341][2][position]"
                      value="y"
                      type="text"
                      hidden={true}
                    />
                    <AvField
                      name="print_heads[gt341][2][color]"
                      value="color"
                      type="text"
                      hidden={true}
                    />
                    <AvField
                      name="print_heads[gt341][2][vrate]"
                      value=""
                      type="text"
                      hidden={true}
                    />
                  </Col>
                </Row>
              </td>
            </tr>
            <tr>
              <td>
                <Row className="mb-2 px-2">
                  <Label md="2"
                    className="col-form-label">PH K
                                    Serial*</Label>
                  <Col md="4">
                    <Printhead
                      example="AGT1234"
                      name="print_heads[gt341][3][serial]"
                      placeholder="PH K Serial *"
                    />
                    <AvField
                      name="print_heads[gt341][3][position]"
                      value="k"
                      type="text"
                      hidden={true}
                    />
                    <AvField
                      name="print_heads[gt341][3][color]"
                      value="color"
                      type="text"
                      hidden={true}
                    />
                    <AvField
                      name="print_heads[gt341][3][vrate]"
                      value=""
                      type="text"
                      hidden={true}
                    />
                  </Col>
                </Row>
              </td>
            </tr>
          </tbody>
        </table>
      </FormGroup>
    </React.Fragment>
  );
};

export default Gt341;
