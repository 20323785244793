import React, {useState, useEffect} from 'react';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label, Alert,
} from 'reactstrap';
import Dropzone from 'react-dropzone';
import {connect} from 'react-redux';
import {createPrinter} from '../../store/printer/actions';
import {withRouter, Link, useHistory} from 'react-router-dom';
import AvSelect from '../../components/Common/AvSelect';
import Gtx from '../Printers/gtx';
import GtxPro from '../Printers/gtxpro';
import GtxProBulk from '../Printers/gtxprobulk';
import Gt341 from '../Printers/gt341';
import Gt361 from '../Printers/gt361';
import Gt381 from '../Printers/gt381';
import Gtx600 from './gtx600';
import Gtx600sb from './gtx600sb';
import ListCustomers from '../../components/Lists/ListCustomers';
import SweetAlert from 'react-bootstrap-sweetalert';

// availity-reactstrap-validation
import {
  AvForm,
  AvGroup,
  AvField,
  AvFeedback,
  AvRadio, AvRadioGroup,
} from 'availity-reactstrap-validation';
import '@availity/reactstrap-validation-select/styles.scss';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import axios from 'axios';
import authHeader from '../../helpers/jwt-token-access/auth-token-header';
import moment from 'moment';
import {getLoggedInUser} from '../../helpers/fakebackend_helper';

const PrinterAdd = (props) => {
  const authUser = getLoggedInUser();

  const printerTypes = authUser && authUser.user.can_see_gtx600 === 1 ? [
    {
      options: [
        {label: 'GTX', value: 'gtx'},
        {label: 'GTXpro', value: 'gtxpro'},
        {label: 'GTXpro Bulk', value: 'gtxprobulk'},
        {label: 'GTX600', value: 'gtx600'},
        {label: 'GTX600SB', value: 'gtx600sb'},
        {label: 'GT-341', value: 'gt341'},
        {label: 'GT-361', value: 'gt361'},
        {label: 'GT-381', value: 'gt381'},
      ],
    },
  ] : [
    {
      options: [
        {label: 'GTX', value: 'gtx'},
        {label: 'GTXpro', value: 'gtxpro'},
        {label: 'GTXpro Bulk', value: 'gtxprobulk'},
        {label: 'GT-341', value: 'gt341'},
        {label: 'GT-361', value: 'gt361'},
        {label: 'GT-381', value: 'gt381'},
      ],
    },
  ];

  const [selectedPrinterType, setSelectedPrinterType] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [errorMsg, setErrorMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [errorFileMsg, setErrorFileMsg] = useState(false);
  const [serialStatus, setSerialStatus] = useState(true);
  const [printerSerialNo, setPrinterSerialNo] = useState('');
  const [existingPrinter, setExistingPrinter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setIsLoading(false);
    if (props.data && props.data.success) {
      setSuccessMsg(true);
    } else if (props.data && props.data.error) {
      setErrorMsg(props.data.error);
    }
  }, [props.data]);

  function handleSelectPrinterType(printerType) {
    setSelectedPrinterType(printerType);
  }

  function handleSelectCustomer(customer) {
    setSelectedCustomer(customer);
  }

  function handleChangePrinterSerial(printerSerialNo) {
    if (printerSerialNo.length === 8) {
      const url = process.env.REACT_APP_APIURL + 'printer/checkduplicate';
      const authToken = authHeader();

      const formData = new FormData();
      formData.append('serial', printerSerialNo);

      return axios.post(url, formData, {headers: authToken}).then(
          (response) => {
            setSerialStatus(response.status === 201);
            setPrinterSerialNo(printerSerialNo);
            setExistingPrinter(null);
          }).catch((error) => {
        setSerialStatus(false);
        setPrinterSerialNo(printerSerialNo);
        setExistingPrinter(error.response.data.printer.id);
      });
    }
  }

  function handleValidSubmit(event, values) {
    if (selectedPrinterType.value === 'gtx600' && !selectedFiles.length) {
      setErrorFileMsg(true);
    } else {
      setIsLoading(true);
      const printer = {
        printer_serial_no: values.printer_serial_no,
        printer_type: selectedPrinterType.value,
        installation_date: values.installation_date,
        print_heads: values.print_heads[selectedPrinterType.value],
        customer_id: selectedCustomer.id,
        files: selectedFiles,
        ink_supply_serial:
          values.ink_supply_serial ? values.ink_supply_serial : null,
        r2r_unit_serial:
          values.r2r_unit_serial ? values.r2r_unit_serial : null,
        is_undamaged: values.is_undamaged ? values.is_undamaged : null,
      };
      props.createPrinter(printer);
    }
  }

  function handleAcceptedFiles(files, target) {
    files.map((file) => Object.assign(file, {
      preview: URL.createObjectURL(file),
      formattedSize: formatBytes(file.size),
      target: target,
      ext: file.name.split('.').reverse()[0],
    }));

    const tempFiles = [...selectedFiles];

    files.map((file) => tempFiles.push(file));

    setSelectedFiles(tempFiles);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          {/* Render Breadcrumb */}
          <Breadcrumbs parent="/printers" title="Printer"
            breadcrumbItem="Add Printer"/>

          <Row>
            <Col xs="12">
              <AvForm className="form-horizontal"
                // model={model}
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}
              >
                {errorMsg ?
                  <SweetAlert
                    title="Error!"
                    danger
                    showConfirm={true}
                    showCancel={false}
                    confirmBtnBsStyle="danger"
                    closeOnConfirm={true}
                    onConfirm={() => {
                      setErrorMsg(false);
                    }}
                  >
                    {errorMsg}
                  </SweetAlert> : ''
                }
                {successMsg ? <SweetAlert
                  title="Good job!"
                  success
                  showConfirm={true}
                  showCancel={false}
                  confirmBtnBsStyle="yellow"
                  onConfirm={() => {
                    setSuccessMsg(false);
                    history.push('/printers');
                  }}
                >
                  The printer has been added!
                </SweetAlert> : null}
                {errorFileMsg ? <SweetAlert
                  title="Missing required files!"
                  warning
                  showConfirm={true}
                  showCancel={false}
                  confirmBtnBsStyle="yellow"
                  onConfirm={() => {
                    setErrorFileMsg(false);
                  }}
                >
                  Please upload required files.
                </SweetAlert> : null}
                <Card>
                  <CardBody>
                    <CardTitle>Printer Serial</CardTitle>
                    <CardSubtitle className="mb-3">Type the Printer Serial
                      No.</CardSubtitle>
                    <FormGroup className="mb-4" row>
                      <Label htmlFor="printer-serialno" md="2"
                        className="col-form-label">
                        Printer Serial No. *</Label>
                      <Col md="10">
                        <AvField
                          name="printer_serial_no"
                          value={printerSerialNo}
                          className="form-control"
                          placeholder="Printer Serial No. *"
                          type="text"
                          validate={
                            {
                              required: {value: true},
                              pattern: {value: '^[A-Z][0-9]+$'},
                              minLength: {value: 8},
                              maxLength: {value: 8},
                              async: (value, ctx, input, cb) => {
                                cb(serialStatus);
                              },
                            }
                          }
                          onChange={
                            (e) => handleChangePrinterSerial(e.target.value)
                          }
                          onInput={
                            (e) => e.target.value = (
                              '' + e.target.value
                            ).toUpperCase()
                          }
                          errorMessage="Please provide a valid serial number."
                          helpMessage="Example: A1234567."
                        />
                        {!serialStatus &&
                          <Alert color="danger">This serial number already
                            exists! Do you want
                            to <Link className="link-highlight"
                            to={'/overtake-printer?id=' + existingPrinter}>
                              overtake printer</Link>?
                          </Alert>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label htmlFor="type_of_business" md="2">Printer is
                        undamaged?</Label>
                      <Col md="4">
                        <AvRadioGroup name="is_undamaged" required>
                          <AvRadio customInput label="Yes" value="1"/>
                          <AvRadio customInput label="No" value="0"/>
                        </AvRadioGroup>
                      </Col>
                    </FormGroup>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle>Customer</CardTitle>
                    <CardSubtitle className="mb-3">Choose a
                      customer</CardSubtitle>
                    <FormGroup className="mb-4" row>
                      <Label md="2"
                        className="col-form-label">Customer *</Label>
                      <Col md="10">
                        <ListCustomers required={true} onChange={(customer) => {
                          handleSelectCustomer(customer);
                        }}/>
                      </Col>
                    </FormGroup>
                    {selectedCustomer &&
                      <Row>
                        <Col sm="10">
                          <FormGroup>
                            <Label htmlFor="street">Street</Label>
                            <Input id="street" name="street" type="text"
                              value={selectedCustomer.street}
                              className="form-control"
                              disabled="disabled"/>
                          </FormGroup>
                        </Col>
                        <Col sm="2">
                          <FormGroup>
                            <Label htmlFor="street_no">No.</Label>
                            <Input id="street_no" name="street_no" type="text"
                              value={selectedCustomer.street_no}
                              className="form-control"
                              disabled="disabled"/>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <Label htmlFor="zip_code">Post code</Label>
                            <Input id="zip_code" name="zip_code"
                              type="text"
                              value={selectedCustomer.zip_code}
                              className="form-control"
                              disabled="disabled"/>
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="city">City</Label>
                            <Input id="city" name="city" type="text"
                              value={selectedCustomer.city}
                              className="form-control"
                              disabled="disabled"/>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <Label htmlFor="country">Country</Label>
                            <Input id="country" name="country"
                              value={selectedCustomer.country}
                              type="text"
                              className="form-control"
                              disabled="disabled"/>
                          </FormGroup>
                        </Col>
                      </Row>
                    }
                    <div>
                      <p>Cant find your customer?</p>
                      <Link to="/add-customer"
                        className="btn btn-yellow">
                        <i className="bx bx-user-circle mr-1"></i> Add
                        new Customer </Link>
                    </div>
                  </CardBody>
                </Card>
                {selectedCustomer &&
                  <Card>
                    <CardBody>
                      <CardTitle>Printer Information</CardTitle>
                      <CardSubtitle className="mb-3">Fill all information
                        below</CardSubtitle>
                      <FormGroup className="mb-4" row>
                        <Label htmlFor="date-of-installation" md="2"
                          className="col-form-label">Installation Date of
                          Machine *</Label>
                        <Col md="10">
                          <AvField
                            name="installation_date"
                            value=""
                            className="form-control"
                            placeholder="Installation Date of Machine *"
                            type="date"
                            max={moment().format('YYYY-MM-DD')}
                            validate={{required: {value: true}}}
                            errorMessage="Provide a valid installation date."
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label md="2" className="col-form-label">Printer
                          Type *</Label>
                        <Col md="10">
                          <AvGroup>
                            <AvSelect
                              name="printer_type"
                              options={printerTypes}
                              helpMessage="Select the printer type."
                              validate={{required: {value: true}}}
                              onChange={(printerType) => {
                                handleSelectPrinterType(printerType);
                              }}
                              raw={true}
                            />
                            <AvFeedback>Please choose a valid
                              option.</AvFeedback>
                          </AvGroup>
                        </Col>
                      </FormGroup>
                      {selectedPrinterType.value === 'gtx' && (
                        <Gtx/>
                      )}
                      {selectedPrinterType.value === 'gtxpro' && (
                        <GtxPro/>
                      )}
                      {selectedPrinterType.value === 'gtxprobulk' && (
                        <GtxProBulk/>
                      )}
                      {selectedPrinterType.value === 'gtx600' && (
                        <Gtx600/>
                      )}
                      {selectedPrinterType.value === 'gtx600sb' && (
                        <Gtx600sb/>
                      )}
                      {['gtx600', 'gtx600sb'].includes(selectedPrinterType.value) && (
                        <>
                          {/* eslint-disable max-len */}
                          <div>
                            <CardTitle>Water quality check*</CardTitle>
                            <CardSubtitle className="mb-3">{'Please check' +
                              ' the water quality with the supplied' +
                              ' conductivity meter. Please upload a photo of ' +
                              'the result. Required conductivity of water is' +
                              ' < 10 μS/cm..'}</CardSubtitle>
                            <Dropzone
                              onDrop={(acceptedFiles) => {
                                handleAcceptedFiles(acceptedFiles, 'water');
                              }}
                              accept=".pdf,.jpg,.jpeg,.png"
                              multiple={false}
                            >
                              {({getRootProps, getInputProps}) => (
                                <div className="dropzone">
                                  <div
                                    className="dz-message needsclick"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="dz-message needsclick">
                                      <div className="mb-3">
                                        <i className="display-4 text-muted bx
                                        bxs-cloud-upload"/>
                                      </div>
                                      <h4>Drop file here or click to
                                        upload.</h4>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            <div
                              className="dropzone-previews mt-3"
                              id="file-previews"
                            >
                              {selectedFiles.filter(
                                  (file) => file.target === 'water').map(
                                  (f, i) => {
                                    return (
                                      <Card
                                        className="mt-1 mb-0 shadow-none
                                      border dz-processing dz-image-preview
                                      dz-success dz-complete"
                                        key={i + '-file'}
                                      >
                                        <div className="p-2">
                                          <Row className="align-items-center">
                                            <Col className="col-auto">
                                              <img
                                                data-dz-thumbnail=""
                                                height="80"
                                                className="avatar-sm rounded
                                                bg-light"
                                                alt={f.name}
                                                src={f.preview}
                                              />
                                            </Col>
                                            <Col>
                                              <Link
                                                to="#"
                                                className="text-muted
                                                font-weight-bold"
                                              >
                                                {f.name}
                                              </Link>
                                              <p className="mb-0">
                                                <strong>
                                                  {f.formattedSize}
                                                </strong>
                                              </p>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Card>
                                    );
                                  })}
                            </div>
                          </div>
                          {/* eslint-enable max-len */}
                        </>
                      )}
                      {selectedPrinterType.value === 'gt341' && (
                        <Gt341/>
                      )}
                      {selectedPrinterType.value === 'gt361' && (
                        <Gt361/>
                      )}
                      {selectedPrinterType.value === 'gt381' && (
                        <Gt381/>
                      )}
                    </CardBody>
                  </Card>
                }
                {selectedCustomer &&
                  <Card>
                    <CardBody>

                      <CardTitle>Upload</CardTitle>
                      <CardSubtitle className="mb-3">We recommend uploading a
                        document in which the
                        customer confirms that they have received a complete
                        installation. This
                        upload field is optional. This document can be the
                        dealer&apos;s own
                        document.</CardSubtitle>
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          handleAcceptedFiles(acceptedFiles, 'install');
                        }}
                        accept=".pdf"
                        multiple={false}
                      >
                        {({getRootProps, getInputProps}) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="dz-message needsclick">
                                <div className="mb-3">
                                  <i
                                    className="display-4 text-muted bx
                                    bxs-cloud-upload"
                                  />
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {selectedFiles.filter(
                            (file) => file.target === 'install').map(
                            (f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border
                              dz-processing dz-image-preview
                              dz-success dz-complete"
                                  key={i + '-file'}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted
                                          font-weight-bold"
                                        >
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              );
                            })}
                      </div>
                    </CardBody>
                  </Card>
                }
                <Row className="my-4">
                  <Col sm="6">
                    <Link to="/printers"
                      className="btn d-none d-sm-inline-block btn-link">
                      <i className="mdi mdi-arrow-left mr-1"></i> Back to
                      Printers Overview
                    </Link>
                  </Col>
                  <Col sm="6">
                    <div className="text-sm-right">
                      {isLoading ?
                        <Button className="btn btn-yellow" type="submit"
                          disabled={true}>
                          <i
                            className="bx bx-loader bx-spin font-size-16
                            align-middle mr-2"/> Add
                          Printer
                        </Button> :
                        <Button className="btn btn-yellow" type="submit">
                          <i className="mdi mdi-plus-thick mr-1"></i> Add
                          Printer
                        </Button>
                      }
                    </div>
                  </Col>
                </Row>

              </AvForm>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


const mapStatetoProps = (state) => {
  const {data} = state.Printer;
  state.Printer = {};
  return {data};
};

export default withRouter(
    connect(mapStatetoProps, {createPrinter})(PrinterAdd),
);
