import React, {useState} from 'react';
import {Alert, Col, FormGroup, Label, Row} from 'reactstrap';
import {AvField} from 'availity-reactstrap-validation';
import Printhead from './printhead';
import Vrate from './vrate';
import authHeader from '../../helpers/jwt-token-access/auth-token-header';
import axios from 'axios';

const GtxPro = (props) => {
  const [serialStatus, setSerialStatus] = useState(true);

  function handleChangemachineSerial(machineSerialNo) {
    if (machineSerialNo.length === 8) {
      const url = process.env.REACT_APP_APIURL + 'machine/checkduplicate';
      const authToken = authHeader();

      const formData = new FormData();
      formData.append('serial', machineSerialNo);

      return axios.post(url, formData, {headers: authToken}).then(
          (response) => {
            setSerialStatus(response.status === 201);
          }).catch((error) => {
        setSerialStatus(false);
      });
    }
  }

  return (
    <React.Fragment>
      <FormGroup className="mb-4">
        <Label className="col-form-label">Print Head Serials</Label>
        <Alert color="danger" role="alert">
            Please note: the following print head serial numbers are the
            original print heads that are installed with the installation
        </Alert>
        <table style={{width: '100%'}}>
          <tbody>
            <tr>
              <td>
                <Row className="mb-2 px-2">
                  <Label md="2"
                    className="col-form-label">PH
                                    Serial colors *</Label>
                  <Col md="4">
                    <Printhead
                      example="GXA1234"
                      name="print_heads[gtxpro][0][serial]"
                      placeholder="PH Serial colors *"
                    />
                    <AvField
                      name="print_heads[gtxpro][0][position]"
                      value="1"
                      type="text"
                      hidden={true}
                    />
                    <AvField
                      name="print_heads[gtxpro][0][color]"
                      value="color"
                      type="text"
                      hidden={true}
                    />
                  </Col>

                  <Label md="2"
                    className="col-form-label">V.Rating
                                    *</Label>
                  <Col md="4">
                    <Vrate name="print_heads[gtxpro][0][vrate]"/>
                  </Col>
                </Row>
              </td>
            </tr>
            <tr>
              <td>
                <Row className="mb-2 px-2">
                  <Label md="2"
                    className="col-form-label">PH
                                    Serial white *</Label>
                  <Col md="4">
                    <Printhead
                      example="XAA1234"
                      name="print_heads[gtxpro][1][serial]"
                      placeholder="PH Serial white *"
                    />
                    <AvField
                      name="print_heads[gtxpro][1][position]"
                      value="2"
                      type="text"
                      hidden={true}
                    />
                    <AvField
                      name="print_heads[gtxpro][1][color]"
                      value="white"
                      type="text"
                      hidden={true}
                    />
                  </Col>
                  <Label md="2"
                    className="col-form-label">V.Rating
                                    *</Label>
                  <Col md="4">
                    <Vrate name="print_heads[gtxpro][1][vrate]"/>
                  </Col>
                </Row>
              </td>
            </tr>
          </tbody>
        </table>
      </FormGroup>
      <FormGroup className="mb-4" row>
        <Label htmlFor="r2r_unit_serial" md="2"
          className="col-form-label">R2R-Unit Serial (optional)</Label>
        <Col md="10">
          <AvField
            name="r2r_unit_serial"
            value=""
            className="form-control"
            placeholder="R2R-Unit Serial*"
            type="text"
            validate={
              {
                pattern: {value: '^[0-9]+$'},
                minLength: {value: 8},
                maxLength: {value: 8},
                async: (value, ctx, input, cb) => {
                  cb(serialStatus);
                },
              }
            }
            onChange={
              (e) => handleChangemachineSerial(e.target.value)
            }
            errorMessage="Please provide a valid serial."
            helpMessage="Example: 001 please input as 00000001 (8 digit number)"
          />
          {!serialStatus && <Alert color="danger">This serial number already exists!</Alert>}
        </Col>
      </FormGroup>
    </React.Fragment>
  );
};

export default GtxPro;
