import React from 'react';
import Moment from 'react-moment';
import {Card, CardBody, CardTitle, Media} from 'reactstrap';
import {getLoggedInUser} from '../../helpers/fakebackend_helper';
import ClaimStatus from '../../components/Claim/ClaimStatus';
import ClaimInternalStatus from '../../components/Claim/ClaimInternalStatus';

const ClaimHistory = (props) => {
  const claim = props.claim;

  const authUser = getLoggedInUser();

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-5">
                        Last Activities
          </CardTitle>
          <ul className="verti-timeline list-unstyled">
            {claim.final_check_status > 0 && <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle font-size-18"/>
              </div>
              <Media>
                <div className="mr-3">
                  <strong className="font-size-14 mb-0">
                    <Moment format="DD-MM-YYYY">{claim.closing_date}</Moment>
                    <i
                      className="bx bx-right-arrow-alt font-size-16
                    text-primary align-middle ml-2 mb-1"
                    />
                  </strong>
                </div>
                <Media body>
                  <div>
                    Warranty Check
                    <ClaimStatus
                      status={claim.status}
                      final_check_status={claim.final_check_status}
                      internal_check_status={claim.internal_check_status}
                    />
                  </div>
                </Media>
              </Media>
            </li>}
            {claim.internal_check_status > 0 &&
              (authUser.user.role === 'manager' ||
                authUser.user.role === 'staff' ||
                authUser.user.role === 'sales'
              ) &&
              <li className="event-list">
                <div className="event-timeline-dot">
                  <i className="bx bx-right-arrow-circle font-size-18"/>
                </div>
                <Media>
                  <div className="mr-3">
                    <strong className="font-size-14 mb-0">
                      <Moment format="DD-MM-YYYY">
                        {claim.internal_check_date}
                      </Moment>
                      <i
                        className="bx bx-right-arrow-alt font-size-16
                      text-primary align-middle ml-2 mb-1"/>
                    </strong>
                  </div>
                  <Media body>
                    <div>
                    Internal Check done by
                      {claim.internal_check_user.firstname}
                      {claim.internal_check_user.lastname}
                      <ClaimInternalStatus
                        internal_check_status={claim.internal_check_status}
                      />
                    </div>
                  </Media>
                </Media>
              </li>}
            {claim.dispatch_confirmed_date &&
              (authUser.user.role === 'manager' ||
                authUser.user.role === 'staff' ||
                authUser.user.role === 'sales') &&
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i
                              className="bx bx-right-arrow-circle font-size-18"
                            />
                          </div>
                          <Media>
                            <div className="mr-3">
                              <strong className="font-size-14 mb-0">
                                <Moment format="DD-MM-YYYY">
                                  {claim.dispatch_confirmed_date}
                                </Moment>
                                <i
                                  className="bx bx-right-arrow-alt font-size-16
                                  text-primary align-middle ml-2 mb-1"
                                />
                              </strong>
                            </div>
                            <Media body>
                              <div>
                                Claim was confirmed by Dispatcher
                                {claim.dispatch_user ?
                                  claim.dispatch_user.firstname + ' ' +
                                  claim.dispatch_user.lastname : ''
                                }
                              </div>
                            </Media>
                          </Media>
                        </li>
            }
            {claim.dispatch_receipt_date && <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle font-size-18"/>
              </div>
              <Media>
                <div className="mr-3">
                  <strong className="font-size-14 mb-0">
                    <Moment format="DD-MM-YYYY">
                      {claim.dispatch_receipt_date}
                    </Moment>
                    <i className="bx bx-right-arrow-alt font-size-16
                    text-primary align-middle ml-2 mb-1"/>
                  </strong>
                </div>
                <Media body>
                  <div>
                    Part arrived at the warehouse
                  </div>
                </Media>
              </Media>
            </li>}
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle font-size-18"/>
              </div>
              <Media>
                <div className="mr-3">
                  <strong className="font-size-14 mb-0">
                    <Moment format="DD-MM-YYYY">{claim.created_at}</Moment>
                    <i
                      className="bx bx-right-arrow-alt font-size-16
                      text-primary align-middle ml-2 mb-1"
                    />
                  </strong>
                </div>
                <Media body>
                  <div>
                    Claim {claim.fault_report_no} was created
                  </div>
                </Media>
              </Media>
            </li>
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle font-size-18"/>
              </div>
              <Media>
                <div className="mr-3">
                  <strong className="font-size-14">
                    <Moment format="DD-MM-YYYY">{claim.date_of_visit}</Moment>
                    <i
                      className="bx bx-right-arrow-alt font-size-16
                      text-primary align-middle ml-2 mb-1"
                    />
                  </strong>
                </div>
                <Media body>
                  <div>
                    On site Customer Service by
                    {claim.engineer.firstname} {claim.engineer.lastname}
                  </div>
                </Media>
              </Media>
            </li>
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle font-size-18"/>
              </div>
              <Media>
                <div className="mr-3">
                  <strong className="font-size-14">
                    <Moment
                      format="DD-MM-YYYY">
                      {claim.printer && claim.printer.installation_date}
                    </Moment>
                    <i
                      className="bx bx-right-arrow-alt font-size-16
                      text-primary align-middle ml-2 mb-1"
                    />
                  </strong>
                </div>
                <Media body>
                  <div>
                    Printer was installed
                  </div>
                </Media>
              </Media>
            </li>
          </ul>
          {/* @TODO delete this? */}
          {/* <div className="text-center mt-4">*/}
          {/*   <Link */}
          {/*     to="" */}
          {/*     className="btn btn-primary waves-effect waves-light btn-sm"*/}
          {/*   >*/}
          {/*     View More <i className="mdi mdi-arrow-right ml-1"/>*/}
          {/*   </Link>*/}
          {/* </div>*/}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ClaimHistory;
