/* eslint-disable no-unused-vars */
export enum PRINTER_TYPE {
    NONE,
    GT3,
    GTX,
    GTXPRO,
    GTXPRO_BULK,
    GTX600,
    GTX600SB
}

export const PrinterTypeMap = new Map<string, PRINTER_TYPE>([
  ['gt341', PRINTER_TYPE.GT3],
  ['gt361', PRINTER_TYPE.GT3],
  ['gt381', PRINTER_TYPE.GT3],
  ['gt-3', PRINTER_TYPE.GT3],
  ['gtx', PRINTER_TYPE.GTX],
  ['gtx', PRINTER_TYPE.GTX],
  ['gtxpro', PRINTER_TYPE.GTXPRO],
  ['gtxprobulk', PRINTER_TYPE.GTXPRO_BULK],
  ['gtx600', PRINTER_TYPE.GTX600],
  ['gtx600sb', PRINTER_TYPE.GTX600SB],
]);

export enum PRINTER_GUARANTEE_STATUS {

}
/* eslint-enable no-unused-vars */
