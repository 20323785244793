import React, {useEffect} from 'react';
import {Collapse} from 'reactstrap';
import {Link, withRouter} from 'react-router-dom';

// i18n
import {withTranslation} from 'react-i18next';

import {connect} from 'react-redux';
import {getLoggedInUser} from '../../helpers/fakebackend_helper';
import {toggleLeftmenu} from '../../store/layout/actions';

const Navbar = (props) => {
  // @TODO Umstellung auf ROLES

  const authUser = getLoggedInUser();

  useEffect(() => {
    let matchingMenuItem = null;
    const ul = document.getElementById('navigation');
    const items = ul.getElementsByTagName('a');
    for (let i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });

  function activateParentDropdown(item) {
    item.classList.add('active');
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add('active'); // li
      const parent2 = parent.parentElement;
      parent2.classList.add('active'); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add('active'); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add('active'); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add('active'); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add('active'); // li
            }
          }
        }
      }
    }
    return false;
  }

  return (<React.Fragment>
    <div className="topnav">
      <div className="container-fluid">
        <nav
          className="navbar navbar-light navbar-expand-lg topnav-menu"
          id="navigation"
        >
          <Collapse
            isOpen={props.leftMenu}
            className="navbar-collapse"
            id="topnav-menu-content"
          >
            <ul className="navbar-nav">

              {authUser.user && [
                'manager', 'staff', 'sales', 'dealer', 'engineer',
              ].includes(authUser.user.role) ?
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/dashboard"
                    onClick={() => {
                      props.toggleLeftmenu(false);
                    }}>
                    <i className="bx bx-home-circle mr-2"/>
                    {props.t('Dashboard')}
                  </Link>
                </li> : ''
              }

              {authUser.user && [
                'manager', 'staff', 'sales', 'dealer', 'engineer',
              ].includes(authUser.user.role) ?
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/machines"
                    onClick={() => {
                      props.toggleLeftmenu(false);
                    }}>
                    {props.t('Machines')}
                  </Link>
                </li> : ''
              }

              {authUser.user && [
                'manager', 'staff', 'sales', 'dealer', 'engineer',
              ].includes(authUser.user.role) ?
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/printers"
                    onClick={() => {
                      props.toggleLeftmenu(false);
                    }}>
                    {props.t('Printer')}
                  </Link>
                </li> : ''
              }

              {authUser.user && [
                'manager', 'staff', 'sales', 'dealer', 'engineer',
              ].includes(authUser.user.role) ?
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/claims"
                    onClick={() => {
                      props.toggleLeftmenu(false);
                    }}>
                    {props.t('Claims')}
                  </Link>
                </li> : ''
              }

              {authUser.user && [
                'manager', 'staff', 'sales', 'dealer', 'engineer',
              ].includes(authUser.user.role) ?
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/customers"
                    onClick={() => {
                      props.toggleLeftmenu(false);
                    }}>
                    {props.t('Customers')}
                  </Link>
                </li> : ''
              }

              {authUser.user && [
                'manager', 'staff', 'sales',
              ].includes(authUser.user.role) ?
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/dealers"
                    onClick={() => {
                      props.toggleLeftmenu(false);
                    }}>
                    {props.t('Dealers')}
                  </Link>
                </li> : ''
              }

              {authUser.user && [
                'manager', 'staff', 'sales',
              ].includes(authUser.user.role) ?
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/members"
                    onClick={() => {
                      props.toggleLeftmenu(false);
                    }}>
                    {props.t('Members')}
                  </Link>
                </li> : ''
              }

              {authUser.user && [
                'manager', 'staff', 'engineer',
              ].includes(authUser.user.role) ?
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/services"
                    onClick={() => {
                      props.toggleLeftmenu(false);
                    }}>
                    {props.t('Maintenance Service')}
                  </Link>
                </li> : ''
              }

              {authUser.user && ['engineer'].includes(authUser.user.role) ?
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/overtake-printer"
                    onClick={() => {
                      props.toggleLeftmenu(false);
                    }}>
                    {props.t('Overtake Printer')}
                  </Link>
                </li> : ''
              }

              {authUser.user && [
                'manager', 'staff',
              ].includes(authUser.user.role) ?
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/overtakes"
                    onClick={() => {
                      props.toggleLeftmenu(false);
                    }}>
                    {props.t('Overtakes')}
                  </Link>
                </li> : ''
              }

              {authUser.user && ['manager'].includes(authUser.user.role) ?
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/notifications"
                    onClick={() => {
                      props.toggleLeftmenu(false);
                    }}>
                    {props.t('Notifications')}
                  </Link>
                </li> : ''
              }

              {authUser.user && ['dispatcher'].includes(authUser.user.role) ?
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/dispatch">
                    <i className="mdi mdi-truck mr-2"/>{props.t('Receipt of' +
                    ' parts')}
                  </Link>
                </li> : ''
              }

              {['bimh', 'bbo'].includes(process.env.REACT_APP_REALM) ?
                <li className="nav-item d-lg-none">
                  <Link to={'/downloads'}>
                    <button
                      type="button"
                      className="btn btn-sm header-item waves-effect waves-light
                      d-flex align-items-center">
                      <i className="mdi mdi-cloud-download font-size-16"/>
                      <span className="font-size-13 ml-2">
                          Software & other tools
                      </span>
                    </button>
                  </Link>
                </li> : ''
              }

              <li className="nav-item d-lg-none">
                <Link to={'/helpcenter'}>
                  <button
                    type="button"
                    className="btn btn-sm font-size-13 header-item waves-effect
                    waves-light d-flex align-items-center">
                    <i className="fa fa-fw fa-question-circle font-size-16"></i>
                    <span className="font-size-13 ml-2">Helpcenter</span>
                  </button>
                </Link>
              </li>

            </ul>
          </Collapse>
        </nav>
      </div>
    </div>
  </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const {leftMenu} = state.Layout;
  return {leftMenu};
};

export default withRouter(
    connect(mapStatetoProps, {toggleLeftmenu},
    )(withTranslation()(Navbar)));

